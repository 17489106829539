<template>
  <v-container justify="center" fluid>
    <v-card>

      <v-card-title>Registreren</v-card-title>

      <v-card-text>
        <p>
          Om toegang te krijgen tot de applicatie, vragen we u om uzelf te registreren. Vul de
          onderstaande gegevens in om de registratie te voltooien:
        </p>
        <p>
        <ol>
          <li>
            <b>E-mailadres:</b>
            <p>Gebruik hiervoor het e-mailadres dat vermeld staat in de uitnodiging</p>
          </li>
          <li>
            <b>Wachtwoord:</b>
            <p>Kies een sterk wachtwoord dat voldoet aan de volgende criteria:
            <ul>
              <li>
                Minimaal 6 tekens lang,
              </li>
              <li>Bevat tenminste één letter, één hoofdletter, één cijfer en één speciaal teken (bijv. @, #, !, %)</li>
            </ul>

            </p>
          </li>
          <li>
            <b>Herhaal wachtwoord</b>
            <p>Voer het wachtwoord opnieuw in om de registratie te voltooien.</p>
          </li>
        </ol>
        </p>


        <v-form v-model="validRegistreer">
          <v-text-field autocomplete="username" :rules="[rules.required, rules.emailRule]" v-model="email" label="E-mail" autofocus
            required></v-text-field>
          <v-text-field autocomplete="new-password" v-model="wachtwoord" :append-icon="showww ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.passwordRule]" :type="showww ? 'text' : 'password'" label="Wachtwoord"
            @click:append="showww = !showww" required></v-text-field>
          <v-text-field autocomplete="new-password" v-model="hh_wachtwoord" :append-icon="showhww ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required]" @click:append="showhww = !showhww" :type="showhww ? 'text' : 'password'"
            label="herhaal wachtwoord" required></v-text-field>

          <v-btn :disabled="!(validRegistreer && (wachtwoord == hh_wachtwoord))" class="mr-4" @click="registreer"
            color="primary">Registreer

          </v-btn>
        </v-form>
      </v-card-text>

    </v-card>

    <div class="mt-5" v-for="(err, index) in errors" :key="index">{{ err }}</div>
  </v-container>
</template>

<script>
import { userService } from "../../api/server/services/userService";

export default {
  name: "Register",
  data: () => ({
    flowIndex: 0,
    validRegistreer: false,
    email: "",
    wachtwoord: "",
    hh_wachtwoord: "",
    rules: {
      required: (v) => !!v || "Waarde is verplicht",
      emailRule: (v) => /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(v) || "Geen geldig emailadres",
      passwordRule: (v) => /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{6,}$/.test(v) || "Wachtwoord is nog niet sterk genoeg",
    },
    showww: false,
    showhww: false,
    errors: [],
  }),
  methods: {
    registreer() {
      userService
        .register(this.email, this.wachtwoord)
        .then((d) => {
          if (d.status === "ok") {
            alert('U bent geregistreerd. U kunt nu inloggen')
            this.$router.push("Login")
            /*
            userService.login(this.email, this.wachtwoord).then((l) => {
              if (l.status === "ok") {
                this.$router.push("Login");
              }
            });*/
          }
        })
        .catch((err) => {
          this.errors = err.errors;
        });
    },
  },
};
</script>