<template>
  <v-container fill-height>
    <v-img :src="require('../assets/vakbekwaamheid.png')" class="my-3" contain height="110" />

    <p class="text--primary">
      Uw organisatie heeft het voornemen om de <b>vakbekwaamheid van haar WOZ-medewerkers</b> te
      evalueren. Hiervoor wordt een <b>vragenlijst</b> gebruikt die gebaseerd is op
      de <b>Waarderingsinstructie.</b>
    </p>

    <p class="text--primary">
      Bij aanvang van de vragenlijst geeft u aan welke <b>rollen</b> u binnen de WOZ vervult. Op basis van
      deze rollen worden <b>inhoudelijke vragen</b> gesteld. De vragenlijst sluit af met vragen
      over <b>tijdsbesteding per rol, ervaringsjaren</b> en <b>genoten opleidingen.</b> Zodra u alle vragen hebt
      ingevuld, kunt u het formulier indienen. Bovendien is het mogelijk om een <b>PDF</b> te genereren
      met daarin uw ingevulde vragen en antwoorden.
    </p>

    <p class="text--primary">
      Uiteraard wordt alle informatie <b>vertrouwelijk behandeld.</b> De resultaten worden alleen gedeeld
      met de opdrachtgever, vaak de leidinggevende.
    </p>

    <p class="text--primary">Veel succes met het invullen van de vragen!</p>

    <v-container>
      <v-row>
        <v-col>
          <v-btn color="blue" to="overzicht">
            <div style="color: white">Vragenlijst</div>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <p class="text--primary mt-5">
      Voor meer informatie over de Waarderingsinstructie kunt u op de onderstaande link klikken:
    </p>

    <v-container>
      <v-row>
        <v-col>
          <router-link to="achtergrond" class="subheading mx-3">Achtergrond</router-link>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>



<script>

export default {
  name: "ClosedLanding"
};
</script>
